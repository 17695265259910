import React from "react";
import "rapidoc";
import Logo from "../../Assets/General/Logo.svg";
import styles from "./Rapidoc.module.css";

const Rapidoc = () => {
  const white = "#ffffff";
  const black = "#000000";
  const mainBlue = "#0141cf";
  const font = "Inter";
  return (
    <rapi-doc
      spec-url="./swagger.yml"
      theme="light"
      render-style="read"
      primary-color={mainBlue}
      load-fonts={true}
      regular-font={font}
      show-info={true}
      allow-server-selection={true}
      header-color={white}
      allow-spec-file-load={false}
      allow-authentication={true}
      bg-color={white}
      font-size="large"
      allow-spec-url-load={false}
      show-curl-before-try={true}
      nav-bg-color={white}
      nav-text-color={black}
      nav-hover-bg-color={mainBlue}
      nav-hover-text-color={white}
      sort-endpoints-by={"none"}
      schema-description-expanded={true}
      schema-style={"table"}
      default-schema-tab={"schema"}
    >
      <img slot="logo" src={Logo} className={styles.Logo} alt="KOINX LOGO" />
    </rapi-doc>
  );
};

export default Rapidoc;
